const CoreConstants = {
    TRUE: 1,
    FALSE: 0,

}

export const ActivityType = {
    CALL : '1',
    APOINTMENT : '2',
    TASK: '3',
    DEADLINE: '4',
    EMAIL: '5',
    FOLLOWUP: '6',
    OTHERS: '7'

}
export const TASK_ICON = {
    call : 'phone',
    meeting : 'event_note',
    task : 'assignment_turned_in',
    deadline : 'query_builder',
    email : 'mail_outline',
    followup : 'person_add',
    others : 'more_horiz',
}


export default CoreConstants;