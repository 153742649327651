import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import './assets/css/calendar-widget.css';
import {createNewCalendarWidgets} from "../../api/calendarApi";
import {fetchCalendarWidgetList} from "../../actions/calendarWidgetAction";
import CalendarWidgetBlock from "./CalendarWidgetBlock";
import EmbedCode from "./EmbedCode";
import Utils from "../../helpers/Utils";
import moment from "moment";
import {confirmAlert} from "react-confirm-alert";
import {useHistory} from "react-router-dom";
import CustomConfirmAlertMod from "../Common/confirm-alert-modified/CustomConfirmAlertMod";

const App = (props) => {

    const [calendarWidgetList, setCalendarWidgetList] = useState(props.calendarWidgetList);
    const [loading, setLoading] = useState(true);
    const [openEmbedCodeModal, setOpenEmbedCodeModal] = useState(false);
    const [embedApiKey, setEmbedApiKey] = useState(null);
    const [embedTitle, setEmbedTitle] = useState(null);
    const history = useHistory();

    const closeEmbedCodeModal = () => {
        setOpenEmbedCodeModal(false);
    }

    useEffect(() => {
        document.title = `Calendar | ${Utils.getAccountData('AuthUserAgencyName')}`;
        window.setActiveSidebar('calendar');
    },[]);

    useEffect(()=>{
        props.fetchCalendarWidgetList();
    }, [loading])

    useEffect(()=>{
        setCalendarWidgetList(props.calendarWidgetList);
    }, [props.calendarWidgetList])

    const fetchList = () => {
        props.fetchCalendarWidgetList();
    }

    const openEmbedWidget = (data) => {
        setEmbedApiKey(data.api_key);
        setEmbedTitle(data.permalink);
        setOpenEmbedCodeModal(true);
    }

    let calendarWidgetListItems = calendarWidgetList.map(function(data,index){
        return(
            < CalendarWidgetBlock key={`${index}-${data.id}-${data.is_default}`} data={data} fetchList={()=>fetchList(true)} openEmbedWidget={(data)=>openEmbedWidget(data)}/>
        )
    });

    const createNewCalendar = () => {

        createNewCalendarWidgets({
            calendar_from : moment().format('Y-MM-DD'),
            calendar_to : moment().add(30, 'days').format('Y-MM-DD')
        }).then(res => {
            if(res.data.status == true) {
                history.push(`/calendar-widget/${res.data.data.id}`);
                window.location.reload();
            }else{
                showAlert('Something wrong!', 'Please try again later');
            }
        });

    }

    const showAlert = (title, message) => {
        let alertTitle = title ? title : 'Cannot perform action';
        let alertMessage = message ? message : 'This calendar is not configured correctly yet!';
        CustomConfirmAlertMod({
            onSubmit : () => {
                return;
            },
            title: alertTitle,
            description: alertMessage,
            submitText: 'Ok, I understand'
        });
    };



    return (
        <React.Fragment>


                <div class="calendar_section">
                    <div class="page_heading">
                        <h4>Calendar Events</h4>
                        <a href="#" onClick={ (event) => createNewCalendar(event) } class="modal-trigger">
                            <svg width="20" height="20" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10" cy="10.4754" r="10" fill="white"/>
                                <path d="M13.8461 10.4754C13.8461 10.8299 13.8153 11.1165 13.4609 11.1165H10.641V13.9363C10.641 14.2902 10.3544 14.3216 9.99996 14.3216C9.64548 14.3216 9.35894 14.2902 9.35894 13.9363V11.1165H6.53906C6.18522 11.1165 6.15381 10.8299 6.15381 10.4754C6.15381 10.1209 6.18522 9.8344 6.53906 9.8344H9.35894V7.01453C9.35894 6.66004 9.64548 6.62927 9.99996 6.62927C10.3544 6.62927 10.641 6.66004 10.641 7.01453V9.8344H13.4609C13.8153 9.8344 13.8461 10.1209 13.8461 10.4754Z" className={"accent--fill--color"}/>
                            </svg>
                            Create New Calendar
                        </a>
                       

                    </div>

                    <div className="page_content calendar_card_new_v2h_top_scroll awesome__scroll_bar">
                       

                            {
                                !calendarWidgetList.length ?
                                <div className="row">
                                    <div className="col s12">
                                            <h5>You don't have any calendar events yet.</h5>
                                            <p>You need to add an event type to allow people to schedule with you.</p>
                                    </div> </div>:
                                    <div className="calendar_card_new_v2h_wrapper">
                                       { calendarWidgetListItems}
                                    </div>

                            }

                        
                    </div>
                </div>

            < EmbedCode
                api_key = {embedApiKey}
                openEmbedCodeModal={openEmbedCodeModal}
                closeEmbedCodeModal={() => closeEmbedCodeModal()}
                calendarTitle = {embedTitle}
            />


        </React.Fragment>
    );
}


const mapStateToProps = state => {
    return {
        calendarWidgetList : state.calendarWidgetReducer.calendarWidgetList,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchCalendarWidgetList : (params) => dispatch(fetchCalendarWidgetList(params)),
    };
}

const CalendarWidget = connect(mapStateToProps, mapDispatchToProps)(App);

export default CalendarWidget;
